import Image from "next/image";
import logo from "@/public/winvotelogowhite.png";

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <div className="flex flex-col relative items-center bg-neutral-900 min-h-[200px] text-neutral-100 mt-12">
      <Image
        src={logo}
        width={240}
        height={240}
        priority
        alt="mph logo"
        className="z-20 my-4"
        style={{ width: "240px", height: "auto" }}
      />
      <div className="flex flex-col gap-2 mb-32">
        <span>&copy; {year} WinVote</span>
        <span>All Rights Reserved</span>
      </div>
    </div>
  );
};

export default Footer;
