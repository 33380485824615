"use client";

import { useState, useEffect } from "react";
import Image from "next/image";
import Avatar from "boring-avatars";
import Link from "next/link";
import { useAccount } from "wagmi";
import { useAtom } from "jotai";
import { userInfoAtom } from "@/app/atoms/users";
import { generateSeed } from "@/app/helpers/generateSeed";
import { ChevronDownIcon } from "@heroicons/react/16/solid";
import {
  UsersAccountReturnType,
  UniversalAvatarProps,
} from "@/app/types/account";
import { useEnsAvatar, useEnsName } from "wagmi";
import ClipboardCopy from "@/app/components/ClipboardCopy";

interface NavAvatarPropsWithSize extends UniversalAvatarProps {
  width?: number;
  height?: number;
  size?: number;
}

export const NavAvatar: React.FC<NavAvatarPropsWithSize> = ({
  displayEnsName = true,
  displayProfileName = true,
  width = 30,
  height = 30,
  size = 25,
}) => {
  const { address } = useAccount();
  const seed = generateSeed(address as `0x${string}`);
  const [users] = useAtom(userInfoAtom);
  const [settings, setSettings] = useState<
    UsersAccountReturnType | undefined
  >();
  const { data: ensName } = useEnsName({ address: address as `0x${string}` });
  const chainId = Number(process.env.NEXT_PUBLIC_CHAIN_ID);
  const { data: ensAvatar } = useEnsAvatar({
    chainId: chainId,
    name: ensName ?? "",
  });

  useEffect(() => {
    const newSettings = users.find(
      (user: UsersAccountReturnType) => user.wallet_address === address,
    );
    if (newSettings) {
      setSettings(newSettings);
    } else if (settings && (!settings.avatar_url || !settings.profile_name)) {
      if (newSettings) {
        setSettings((prevSettings) => ({
          ...prevSettings,
          avatar_url: (newSettings as UsersAccountReturnType)?.avatar_url,
          profile_name: (newSettings as UsersAccountReturnType)?.profile_name,
        }));
      }
    }
  }, [address, users, settings]);

  return (
    <div className="flex items-center mx-auto gap-2 px-2">
      {settings?.avatar_url ? (
        <Image
          src={settings.avatar_url}
          alt="Avatar"
          width={width}
          height={height}
          className="rounded-full z-20 border border-neutral-300"
          style={{ width: `${width}px`, height: `${height}px` }}
        />
      ) : ensAvatar ? (
        <Image
          src={ensAvatar}
          alt="Avatar"
          width={width}
          height={height}
          className="rounded-full z-20"
          style={{ width: `${width}px`, height: `${height}px` }}
        />
      ) : (
        <Avatar
          size={size}
          name={seed}
          variant="beam"
          colors={[
            "#0782b7",
            "#ed2e94",
            "#fa9005",
            "#083ff4d4",
            "#3ec300",
            "#f4f807",
            "#b01eca",
          ]}
        />
      )}

      {settings?.profile_name && displayProfileName ? (
        <Link
          href={`/karratco/view/profile/${address}`}
          title={address}
          className="text-sm cursor-pointer"
        >
          {settings.profile_name}
        </Link>
      ) : ensName && displayEnsName ? (
        <Link
          href={`/karratco/view/profile/${address}`}
          title={address}
          className="text-sm cursor-pointer"
        >
          {ensName}
        </Link>
      ) : (
        <ClipboardCopy address={address!} truncate="64" />
      )}
      <ChevronDownIcon
        className="w-4 h-4 mr-2 transition-transform duration-300 transform group-hover:-rotate-180"
        fill="currentColor"
      />
    </div>
  );
};
