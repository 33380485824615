import React, { useEffect } from "react";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { useAccount } from "wagmi";
import { twMerge } from "tailwind-merge";
import { useGetOneUser } from "@/app/hooks/Database/useGetOneUser";

interface ConnectProps {
  className?: string;
  buttonClassName?: string;
}

export default function Connect({ buttonClassName = "" }: ConnectProps) {
  const { open } = useWeb3Modal();
  const { isConnected } = useAccount();
  const { fetchData } = useGetOneUser();

  useEffect(() => {
    if (isConnected) {
      fetchData();
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [isConnected]);

  return (
    <>
      {!isConnected && (
        <button
          onClick={() => open()}
          className={twMerge(
            "flex items-center px-4 py-2 rounded-full uppercase text-[12px] my-8",
            buttonClassName,
          )}
        >
          Connect Wallet
        </button>
      )}
    </>
  );
}
