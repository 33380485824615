"use client";

import { useEffect } from "react";
import { useSetAtom, useAtomValue } from "jotai";
import { useAccount } from "wagmi";
import { fetchDelegatesAndBalances } from "@/app/actions/fetchDelegates";
import { delegatesAtom, delegatorsAtom } from "@/app/atoms/delegates";
import { userDataAtom } from "@/app/atoms/userData";
import { DelegateInfo } from "@/app/types/delegates";
import { useQueryClient, useQuery } from "@tanstack/react-query";
import { getVotingPower } from "@/app/helpers/contracts/token/read";
import { proposalThresholdAtom } from "@/app/atoms/governorContract";
import { tokenInfoAtom } from "../atoms/token";

export const useInitializeDelegates = () => {
  const queryClient = useQueryClient();
  const setDelegates = useSetAtom(delegatesAtom);
  const setUserDelegate = useSetAtom(delegatorsAtom);
  const setUserData = useSetAtom(userDataAtom);
  const { address: userAddress } = useAccount();
  const proposalThreshold = useAtomValue(proposalThresholdAtom);
  const tokenInfo = useAtomValue(tokenInfoAtom);
  const circulatingSupply = tokenInfo.circulatingSupply;

  const fetchDelegatesAndBalancesWithCache = async (): Promise<
    DelegateInfo[]
  > => {
    const data = await fetchDelegatesAndBalances();
    queryClient.setQueryData(["delegates"], data);
    return data;
  };

  const {
    data: delegatesData,
    isLoading,
    error,
  } = useQuery<DelegateInfo[]>({
    queryKey: ["delegates"],
    queryFn: fetchDelegatesAndBalancesWithCache,
    staleTime: 5 * 60 * 1000, // 5 minutes
    retry: 2,
  });

  useEffect(() => {
    if (delegatesData) {
      setDelegates(delegatesData);
    }
  }, [delegatesData, setDelegates]);

  useEffect(() => {
    const fetchAndSetUserData = async () => {
      if (userAddress && delegatesData) {
        const userDelegateInfo = delegatesData.find((delegate) =>
          delegate.delegators?.includes(userAddress as `0x${string}`),
        );

        const userDelegateCount = userDelegateInfo ? 1 : 0;
        const userDelegateAddresses = userDelegateInfo
          ? [userDelegateInfo.address]
          : [];

        const isUserSelfDelegated = userDelegateAddresses.includes(
          userAddress as `0x${string}`,
        );

        const userDelegatorsAddresses =
          delegatesData
            .filter((delegate) => delegate.address === userAddress)
            .flatMap((delegate) => delegate.delegators) || [];

        const votingPower = await getVotingPower(userAddress as `0x${string}`);

        const cleanVotingPower = parseInt(votingPower.replace(/,/g, ""));
        const proposalThresholdNumber = Number(
          proposalThreshold?.replace(/,/g, ""),
        );

        const meetsProposalThreshold =
          cleanVotingPower >= proposalThresholdNumber;

        setUserData((prevUserData: any) => ({
          ...prevUserData,
          address: userAddress as `0x${string}`,
          delegateCount: userDelegateCount,
          delegatesAddress: userDelegateAddresses,
          delegatorsAddresses: userDelegatorsAddresses,
          delegatorsCount: userDelegatorsAddresses.length,
          isUserSelfDelegated,
          votingPower,
          meetsProposalThreshold,
        }));
      }
    };

    fetchAndSetUserData();
  }, [
    delegatesData,
    setUserDelegate,
    setUserData,
    userAddress,
    proposalThreshold,
    circulatingSupply,
  ]);
};
