"use client";

import React from "react";
import {
  QueryClient,
  QueryClientProvider,
  QueryCache,
  MutationCache,
} from "@tanstack/react-query";
import { WagmiProvider } from "wagmi";
import { SessionProvider } from "next-auth/react";
import { TokenContextProvider } from "./TokensContext";
import { createWeb3Modal } from "@web3modal/wagmi/react";
import { Provider as JotaiProvider } from "jotai";
import { config, projectId } from "@/app/connection/config";
import { siweConfig } from "@/app/connection/siweConfig";

if (!projectId) throw new Error("Project ID is not defined");

const queryCache = new QueryCache({
  onError: (error) => {
    console.error("Query Error");
  },
  onSuccess: (data) => {
    console.log("Query Success");
  },
  onSettled: (data, error) => {
    console.log("Query Settled");
  },
});

const mutationCache = new MutationCache({
  onError: (error, variables, context) => {
    console.error("Mutation Error");
  },
  onSuccess: (data, variables, context) => {
    console.log("Mutation Success");
  },
  onSettled: (data, error, variables, context) => {
    console.log("Mutation Settled");
  },
});

const queryClient = new QueryClient({
  queryCache,
  mutationCache,
  defaultOptions: {
    queries: {
      staleTime: 5 * 60 * 1000, // 5 minutes
      retry: 2,
    },
    mutations: {
      onError: (error) => {
        console.error("Global Mutation Error:", error);
      },
      onSuccess: (data) => {
        console.log("Global Mutation Success:", data);
      },
    },
  },
});

const web3Modal = createWeb3Modal({
  wagmiConfig: config,
  siweConfig,
  projectId,
  enableAnalytics: true,
  enableOnramp: true,
});

export function Providers({
  children,
  initialState,
  session,
}: {
  children: React.ReactNode;
  initialState: any;
  session: any;
}) {
  const queryClient = new QueryClient();

  return (
    <SessionProvider session={session} refetchInterval={60}>
      <WagmiProvider config={config}>
        <QueryClientProvider client={queryClient}>
          <JotaiProvider>
            <TokenContextProvider>{children}</TokenContextProvider>
          </JotaiProvider>
        </QueryClientProvider>
      </WagmiProvider>
    </SessionProvider>
  );
}
