import React, { useEffect, useState } from "react";
import { useDisconnect, useAccount, useEnsName, useBalance } from "wagmi";
import { BigNumberish, formatUnits } from "ethers";
import { useAtom, useAtomValue } from "jotai";
import { tokenInfoAtom } from "@/app/atoms/token";
import ClipboardCopy from "../ClipboardCopy";
import Link from "next/link";
import { abbreviateValue } from "@/app/helpers/abbreviateValue";
import { config } from "@/app/connection/config";
import { userInfoAtom } from "@/app/atoms/users";
import { UsersAccountReturnType } from "@/app/types/account";

interface DropdownProps {
  isVisible: boolean;
  width: number;
  className?: string;
}

const Dropdown: React.FC<DropdownProps> = ({ isVisible, width, className }) => {
  const { address, chain, isConnected } = useAccount();
  const [users] = useAtom(userInfoAtom);
  const [settings, setSettings] = useState<
    UsersAccountReturnType | undefined
  >();

  const { disconnect } = useDisconnect({ config });
  const { data: ensName } = useEnsName({ address });
  const { data: chainBalance } = useBalance({ address });
  const tokenInfo = useAtomValue(tokenInfoAtom);

  useEffect(() => {
    const newSettings = users.find(
      (user: UsersAccountReturnType) => user.wallet_address === address,
    );
    setSettings(newSettings);
  }, [address, users]);

  if (!isVisible || !isConnected) return null;

  const rawNativeValue =
    chainBalance?.value &&
    parseFloat(formatUnits(chainBalance.value as BigNumberish, 18));
  const formattedNativeValue =
    rawNativeValue !== undefined
      ? rawNativeValue >= 1_000_000
        ? abbreviateValue(Number(rawNativeValue))
        : Number(rawNativeValue).toFixed(2)
      : null;

  const nativeSymbol =
    chain?.name === "Sepolia" ? "SepoliaETH" : chainBalance?.symbol;

  const nativeBalance =
    formattedNativeValue && nativeSymbol ? (
      `${formattedNativeValue} ${nativeSymbol}`
    ) : (
      <div className="text-center">Loading...</div>
    );

  const usersAddress = address ? (
    <div className="flex text-center justify-center">
      <ClipboardCopy
        truncate={"nav"}
        address={address || settings?.wallet_address}
      />
    </div>
  ) : (
    <div className="text-center">Loading...</div>
  );

  const handleDisconnect = () => {
    disconnect();
  };

  const dropdownContent = [
    (ensName || settings?.wallet_address) && (
      <div key="address" className="px-4 py-2 w-full">
        {usersAddress}
      </div>
    ),
    nativeBalance && (
      <div
        key="balance"
        className="cursor-default px-4 py-2 w-full text-center whitespace-nowrap"
      >
        {nativeBalance}
      </div>
    ),
    tokenInfo.balance && (
      <div
        key="balance"
        className="cursor-default px-4 py-2 w-full text-center whitespace-nowrap"
      >
        {tokenInfo.balance} {`${tokenInfo.symbol}s` ?? ""}
      </div>
    ),
    <Link
      href="/karratco/view/settings"
      key="settings"
      className="cursor-pointer px-4 py-2 w-full text-center flex justify-center uppercase"
    >
      Settings
    </Link>,
    <button
      key="disconnect"
      onClick={handleDisconnect}
      className="cursor-pointer px-4 py-2 w-full uppercase"
    >
      Disconnect
    </button>,
  ];

  return (
    <div
      className="absolute top-[220px] xs:top-[160px] ipad:top-[56px] rounded-b-md z-40 bg-neutral-800"
      style={{ width: `${width}px` }}
    >
      <ul>
        {dropdownContent.map((option, index) => (
          <li
            key={index}
            className={`${
              className ?? ""
            } hover:bg-neutral-700 cursor-pointer text-[12px] ${
              index === dropdownContent.length - 1 ? "rounded-b-md" : ""
            }`}
          >
            {option}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Dropdown;
