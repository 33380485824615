import { useEffect } from "react";
import { useAtom } from "jotai";
import { userInfoAtom } from "@/app/atoms/users";

export const useGetProfile = (isLocked: Boolean, postMade: number) => {
  const [, setSettings] = useAtom(userInfoAtom);
  useEffect(() => {
    if (!isLocked || postMade) {
      const fetchData = async () => {
        try {
          const response = await fetch("/api/get-user-table");
          const data = await response.json();

          setSettings(data);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      fetchData();
    }
  }, [isLocked, postMade, setSettings]);
};
