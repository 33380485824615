"use client";

import { useRef } from "react";
import Image from "next/image";
import logo from "@/public/karrat.png";
import Link from "next/link";
import { useAccount } from "wagmi";
import { LinkIcon } from "@heroicons/react/24/solid";
import twitterLogo from "@/public/twitter.svg";
import Profile from "./Profile";
import Connect from "@/app/components/Connect";

const Nav: React.FC = () => {
  const navRef = useRef<HTMLDivElement | null>(null);
  const { isConnected } = useAccount();

  return (
    <nav
      ref={navRef}
      className="flex flex-col justify-center items-center sm:flex-row xs:justify-between  min-h-[75px] xxs:mx-4 max-xxs:mx-0 sm:mx-16 lg:mx-24 xxl:mx-64"
    >
      <div className="logo-container max-w-fit flex flex-col">
        <Link href="/" className="cursor-pointer flex">
          <Image
            src={logo}
            width={240}
            height={60}
            sizes="(min-width: 808px) 50vw, 100vw"
            priority
            alt="mph logo"
            className="z-20 mt-4 mb-5 xs:mb-0 mx-auto sm:mx-0"
            style={{ width: "240px", height: "60px" }}
          />
        </Link>
        <div className="ml-2">Powered by WinVote</div>

        <div className="flex flex-col xs:flex-row gap-2 min-w-max max-w-fit sm:mt-12">
          <Link
            href="https://www.karratcoin.com/"
            className="flex gap-1 cursor-pointer hover:bg-neutral-950 px-4 py-1 rounded-full justify-center items-center"
            target="_blank"
            rel="noopener noreferrer"
          >
            <LinkIcon className="w-4 h-4 text-yellow" />
            karratcoin.com
          </Link>

          <Link
            href="https://twitter.com/karratcoin"
            className="flex gap-1 cursor-pointer hover:bg-neutral-950 px-0 xs:px-4 py-1 rounded-full justify-center items-center"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image
              src={twitterLogo}
              alt="Twitter Logo"
              width={20}
              height={20}
              style={{ width: "20px", height: "20px", color: "white" }}
            />
            @karratcoin
          </Link>
        </div>
      </div>
      <div className="flex mb-auto">
        {isConnected ? (
          <Profile />
        ) : (
          <Connect buttonClassName="metal metalLink border border-neutral-700 hover:border-neutral-950 hover:bg-black" />
        )}
      </div>
    </nav>
  );
};

export default Nav;
