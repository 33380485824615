"use client";

import { useEffect } from "react";
import { useSetAtom } from "jotai";
import {
  proposalThresholdAtom,
  quorumNumeratorAtom,
  quorumDenominatorAtom,
  votingPeriodAtom,
  votingDelayAtom,
  circulatingSupplyAtom,
  tokenAddressAtom,
  timelockAddressAtom,
  quorumNeededAtom,
} from "@/app/atoms/governorContract";
import {
  fetchProposalThreshold,
  fetchQuorumNumerator,
  fetchQuorumDenominator,
  fetchVotingPeriod,
  fetchVotingDelay,
  fetchTokenAddress,
  fetchTimelockAddress,
} from "@/app/helpers/contracts/governor/read";

export const useFetchGovernorData = () => {
  const setProposalThreshold = useSetAtom(proposalThresholdAtom);
  const setQuorumNumerator = useSetAtom(quorumNumeratorAtom);
  const setQuorumDenominator = useSetAtom(quorumDenominatorAtom);
  const setVotingPeriod = useSetAtom(votingPeriodAtom);
  const setVotingDelay = useSetAtom(votingDelayAtom);
  const setCirculatingSupply = useSetAtom(circulatingSupplyAtom);
  const setTokenAddress = useSetAtom(tokenAddressAtom);
  const setTimelockAddress = useSetAtom(timelockAddressAtom);
  const setQuorumNeeded = useSetAtom(quorumNeededAtom);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [
          proposalThreshold,
          quorumNumerator,
          quorumDenominator,
          votingPeriodData,
          votingDelayData,
          tokenAddress,
          timelockAddress,
        ] = await Promise.all([
          fetchProposalThreshold(),
          fetchQuorumNumerator(),
          fetchQuorumDenominator(),
          fetchVotingPeriod(),
          fetchVotingDelay(),
          fetchTokenAddress(),
          fetchTimelockAddress(),
        ]);

        // Fetch circulating supply from the /cs API
        const circulatingSupplyResponse = await fetch(
          "https://klaims.karratcoin.com/cs",
        );
        const circulatingSupply = await circulatingSupplyResponse.text();

        const circulatingSupplyValue = parseFloat(
          circulatingSupply.replace(/,/g, ""),
        );

        const quorumNeeded = (
          (circulatingSupplyValue * parseFloat(quorumNumerator)) /
          parseFloat(quorumDenominator)
        ).toFixed(2);

        const { votingPeriodInBlocks, formattedVotingPeriod } =
          votingPeriodData;
        const { formattedVotingDelay } = votingDelayData;

        setProposalThreshold(proposalThreshold);
        setQuorumNumerator(quorumNumerator);
        setQuorumDenominator(quorumDenominator);
        setVotingPeriod(formattedVotingPeriod);
        setVotingDelay(formattedVotingDelay);
        setCirculatingSupply(circulatingSupply);
        setTokenAddress(tokenAddress);
        setTimelockAddress(timelockAddress);
        setQuorumNeeded(quorumNeeded.toString());
      } catch (error) {
        console.error("Error fetching governor data:", error);
      }
    };

    fetchData();
  }, [
    setProposalThreshold,
    setQuorumNumerator,
    setQuorumDenominator,
    setVotingPeriod,
    setVotingDelay,
    setCirculatingSupply,
    setTokenAddress,
    setTimelockAddress,
    setQuorumNeeded,
  ]);
};
